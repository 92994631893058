import { API_METHOD_POST, EXECUTE_QUERY, API_METHOD_GET, API_METHOD_DEL, GET_USER_DATA, EXECUTE_QUERY_PN_DROPDOWN , TRIGGER_HTML_GENERATION, DELETE_DIRECTORY} from "./api.actions";
import requestApi from "./api.request";
import getConfig from "../config";
import { API_CALL } from "./api.middleware";
import { GET_USERINFO_SUCCESS, GET_USERINFO_FAILURE } from "../models/userDataModel/userDataActionType";

const config = getConfig();
const apiName = config.API.endpoints[0].name; // api endpoint resolved from aws for the project
const API_USERPOOL = config.API.endpoints[1].name; // api endpoint resolved from aws for the project

//GET dynamic data
export const apiDynamicData = async (source) => {
  return await requestApi({
    apiName: apiName,
    path: EXECUTE_QUERY.replace("{key}", source.report).replace(
      "{id}",
      source.value.dataset
    ),
    method: API_METHOD_POST,
    params: { body: { query: source.value } }
  }).then(response => {
    return response.body;
  });
};

export const apiPostData = async (path, body) => {
  return await requestApi({
    apiName: apiName,
    path,
    method: API_METHOD_POST,
    params: { body }
  }).then(response => {
    return response;
  });
};

//GET user data
export const apiUserData = async (source) => {
  return await requestApi({
    apiName: apiName,
    path: GET_USER_DATA.replace("{key}", source.report),
    method: API_METHOD_GET
  }).then((response) => {
    return { data : response.body.userdata ? response.body.userdata[source.key]: [] };
  }).catch(error => {
    console.log("Err ->", error);
    return error;
  });
};


export const getData = async (sources, selectedReport, targetKey="details.value") => {
  const sourceData = sources.find(item => item.key === targetKey);
  if (sourceData.type === "staticData") {
    return sourceData.value;
  } else if (sourceData.type === "dynamicData") {
    const source = {
      ...sourceData,
      report: selectedReport
    };
    return await apiDynamicData(source);
  } else if (sourceData.type === "userData") {
    const source = {
      ...sourceData,
      report: selectedReport
    };
    return await apiUserData(source);
  } else {
    console.log(`Err -> unsupported source type:: ${sourceData.type}`);
  }
};

export const triggerHtmlFileTransfer = async (source) => {
  const payload = {
    "url" : source.url
  }
    return await requestApi({
      apiName: apiName,
      path: TRIGGER_HTML_GENERATION.replace("{key}", source.dossierKey),
      method: API_METHOD_POST,
      params: { body: payload }
    }).then(response => {
      return response.status;
    });
};

export const deleteOlderversionsOfPDf = async (dossierKey) => {
    return await requestApi({
      apiName: apiName,
      path: DELETE_DIRECTORY.replace("{key}", dossierKey),
      method: API_METHOD_DEL
    }).then(response => {
      return response.status;
    });
};

export const getPNDropdownComponentReliability = async (text) => {
  const payload = {
    "dashboard_name":"gd",
    "dossier_type":"system",
    "primary_scope":"component_reliability",
    "secondary_scope":"component_reliability_dropdown",
    "pn_dropdown":text,
  }
  return await requestApi({
    apiName: apiName,
    path: EXECUTE_QUERY_PN_DROPDOWN,
    method: API_METHOD_POST,
    params: { body: payload }
  }).then(response => {
    return response.part_number;
  });
}

export const getDataWithMultipleSources = async (sources, selectedReport, targetKey = 'details.value') => {
  const promises = [];
  const sourceData = sources.find(item => item.key === targetKey);
  switch (sourceData.type) {
    case 'staticData': {
      return [sourceData.value];
    }
    case 'dynamicData': {
      sources.forEach(source => {
        promises.push(apiDynamicData({...source, report: selectedReport}));
      });
      const proms = await Promise.allSettled(promises);
      return proms.map(it => it);
    }
    case '': {
      return await apiUserData({
        ...sourceData,
        report: selectedReport
      });
    }
    default:
      console.log(`Err -> unsupported source type:: ${sourceData.type}`);
      break;
  }
}

export const getAuthUserInfo = () => {
  const authUserInfoSuccess = (response) => { return { type: GET_USERINFO_SUCCESS, payload: response } }; // eslint-disable-line no-unused-vars
  const authUserInfoFailure = (err) => { return { type: GET_USERINFO_FAILURE, error: err } }; // eslint-disable-line no-unused-vars

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName: API_USERPOOL,
      path: '/oauth2/userInfo',
      method: API_METHOD_GET,
      isAccess: true,
      params: {},
    },
    handlers: [ authUserInfoSuccess, authUserInfoFailure ]
  };
};
