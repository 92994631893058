import React from 'react';
import {Button} from "@airbus/components-react";
import idx from "idx";

/**
 * Component to render the "Open to SRS" button
 * @param props {dates, pns, operators, maintenanceProgram}
 * @return {JSX.Element}
 * @constructor
 */

const SRSButton = (props) => {
  const {dates, pns, operators, maintenanceProgram} = idx(props, _ => _);
  const {startDate, endDate} = dates;
  const baseUrl = 'https://core.skywise.com/workspace/foundry-slate/app/skywise-reliability-services?from=mpo&v_tab=3&filter_dimension=PN';
  const acType = maintenanceProgram && maintenanceProgram.toLowerCase() === "a320 family" ? "A320" : maintenanceProgram;

  /**
   * function to build url with acType, pns, operators, startDate & endDate
   * @returns {string}
   */
  const buildUrl = () => {
    let url = `${baseUrl}`;
    url += acType ? `&ac_type=${acType}` : '';
    url += pns ? `&pn=${pns}` : '';
    url += (operators && `&operator=${operators}`) ?? '';
    url += startDate && endDate ? `&date_range_from=${startDate}&date_range_to=${endDate}` : '';
    return url;
  };

  return (
    <a target="_blank" rel="noopener noreferrer" href={`${buildUrl()}`}>
    <Button variant="ghost"
            className="edit-btn padding-paste-add"
            size="small"
            id="srs">
      Open to SRS
    </Button>
  </a>
  );
};

export default SRSButton;
