import {getCustomFieldsCR, isFieldValid} from "../../../ComponentReliability/utils";
import {apiPostData} from "../../../../utils/data.request";
import {EXECUTE_QUERY_FOUNDRY_POSTGRE} from "../../../../utils/api.actions";
import {isJSON} from "../../Table/TableUtils";
import {cloneDeep} from "lodash";

const CryptoJS = require("crypto-js");
const messages = {
    ERROR: "Invalid data in clipboard. Please copy SRS data (JSON)",
    SUCCESS: "Line successfully added",
    WARNING: "Line is already existing"

}

const mappingJsonKeySRS = {
    "PN Family": "family_id",
    "MTBUR": "PN_MTBUR",
    "A/C Program": "AC_Program"
}

/**
 * Function to hash value from a string (MD5 algorithm)
 * @param string
 * @returns {*}
 */
export function hash(string) {
    const result = CryptoJS.SHA256(string).toString();
    return result
}
/**
 * Reformat SRS Json to be correct json format
 * @param text
 * @param toastData
 * @returns {*[]}
 */
export const reformatSRSJson = (text, toastData) => {
    const jsonTest = isJSON(text)
    if (jsonTest) {
        const json = JSON.parse(text)
        const result = []
        const keys = Object.keys(json)
        const nbOfRows = json[keys[0]].length
        let index = 0
        while (index < nbOfRows) {
            const tempMap = {}
            for (const key in json) {
                const newKey = mappingJsonKeySRS[key] || key
                tempMap[newKey] = json[key][index]
            }
            result.push(tempMap)
            index++
        }
        return result
    } else {
        toastData.push({status: "error", message: messages.ERROR})
    }

}

/**
 * Check validity of data for CR and clean undesired columns
 * @param line
 * @param customField
 * @param initData
 * @param columns
 * @returns {object}
 */
export const cleanLinePasteData = (line, customField, initData, columns) => {
    const result = columns.reduce((acc, curr) => { //Remove undesired columns
        if (line[curr.key]) {
            acc[curr.key] = line[curr.key]
        }
        const field = customField.filter(elt => elt.key === curr.key)[0]
        if (field) {
            if (field.key === "PN_MTBUR" && !isFieldValid(field, line, initData)) {
                acc[curr.key] = "N/A"
            }
        }
        return acc
    }, {})
    return result
}

/**
 * Stringify and reformat JSON removing dynamical number of key (CR) (e.g. primaryKey, status column...)
 * @param initData
 * @param rest
 * @returns {array}
 */

export const stringifyDataNoPrimaryKeyFunc = (initData, ...rest) => {
    let result = initData
    for (const keyParam of rest) {
        const reducedInitData = result.reduce((acc, curr) => {
            const map = {}
            for (const key in curr) {
                if (key !== keyParam) {
                    map[key] = curr[key]
                }
            }
            acc.push(map)
            return acc
        }, [])
        result = reducedInitData
    }
    return result.map(elt => JSON.stringify(elt, Object.keys(elt).sort()).replace(/": "/gm, "\":\""))
}

/**
 * Return custom array of fields depending on if line contains PN or family_id
 * @param hasPN
 * @param hasPNFamily
 * @param program
 * @returns {Array}
 */
export const hasPNOrPNFamily = (hasPN, hasPNFamily, program) => {
    let customFieldsCR = getCustomFieldsCR(program)
    if (hasPN && !hasPNFamily) {
        customFieldsCR = customFieldsCR.filter(elt => elt.key !== "family_id")

    } else /* istanbul ignore else */
    if (!hasPN && hasPNFamily) {
        customFieldsCR = customFieldsCR.filter(elt => elt.key !== "PN")
    }
    return customFieldsCR
}


/**
 * Create primary key map with fields and value based on Date.now()
 * @param program
 * @param cleanedLine
 * @returns {{mapPrimaryKey: {object}, primaryKey: string}}
 */
export const createMapPrimaryKey = (program, cleanedLine) => {
    const filteredPrimaryKeys = getCustomFieldsCR(program).filter(elt => elt.primaryKey)
    const primaryKey = filteredPrimaryKeys[0].primaryKey
    const valuePrimaryKey = hash(filteredPrimaryKeys.reduce((acc, elt) => {
        acc.push(cleanedLine[elt.key])
        return acc
    }, []).join('_')) + '_' + Date.now()
    const mapPrimaryKey = {}
    mapPrimaryKey[primaryKey] = valuePrimaryKey

    return {mapPrimaryKey, primaryKey}
}

/**
 * Check if each column is valid with a SkipCustomColumnsFilter Array
 * @param columns
 * @param customFieldsCR
 * @param skipCustomColumnsFilter
 * @param line
 * @param initData
 * @returns {*}
 */
export const isValidValueCR = (columns, customFieldsCR, skipCustomColumnsFilter, line, initData) => {
    const result = columns.every(initField => {
        const field = customFieldsCR.filter(elt => !skipCustomColumnsFilter.includes(elt.key) && elt.key === initField.key)[0]
        if (field) {
            return isFieldValid(field, line, initData)
        }
        return true
    })
    return result

}

/**
 * Build Payload to send to back to get mapping PN/familiy_id/Component depending on having PN or family_id
 * @param hasPN
 * @param hasPNFamily
 * @param cleanedLine
 * @returns {{primary_scope: string, dashboard_name: string, secondary_scope: string, dossier_type: string, PN}|{primary_scope: string, family_id, dashboard_name: string, secondary_scope: string, dossier_type: string}}
 */
export const buildPayloadMapping = (hasPN, hasPNFamily, cleanedLine) => {
    const payload = {
        dashboard_name: "gd",
        dossier_type: "system",
        primary_scope: "component_reliability",
        secondary_scope: "component_pn_value"
    }
    let result;
    if (hasPN && !hasPNFamily) {
        result = {...payload, "PN": cleanedLine["PN"]}
    } else /* istanbul ignore else */
    if (!hasPN && hasPNFamily) {
        result = {...payload, "family_id": cleanedLine["family_id"]}
    }
    return result
}

/**
 * Build line after getting mapping from the back
 * @param data
 * @param cleanLinePasteDataResult
 * @returns {*&{family_id: (string|*), Component: (string|string[]|*), PN: (string[]|string|*)}}
 */
export const buildCleanLine = (data, cleanLinePasteDataResult) => {
    const cleanedLine = {
        ...cleanLinePasteDataResult,
        family_id: data.id_family || cleanLinePasteDataResult["family_id"],
        PN: data.part_number || cleanLinePasteDataResult["PN"],
        Component: data.component_description || cleanLinePasteDataResult["Component"]
    }
    const cleanedData = {
        ...cleanedLine,
        "Component": joinArray(cleanedLine, "Component")
    }

    return cleanedData
}

/**
 * Handle Valid Values and populate toastData depending on the cases (Duplicates, Invalid format, Success ...)
 * @param line
 * @param customFieldsCR
 * @param initData
 * @param columns
 * @param hasPN
 * @param hasPNFamily
 * @param toastData
 * @param program
 * @returns {Promise<any[]|*[]>}
 */
export const handleValidValues = async (line, customFieldsCR, initData, columns, hasPN, hasPNFamily, toastData, program) => {
    const cleanLinePasteDataResult = cleanLinePasteData(line, customFieldsCR, initData, columns)
    const payload = buildPayloadMapping(hasPN, hasPNFamily, cleanLinePasteDataResult)
    const data = payload ? await apiPostData(EXECUTE_QUERY_FOUNDRY_POSTGRE, payload).catch(() => { /* istanbul ignore next */
        toastData.push({status: "error", message: "Error API Call"})
    }) : null
    if (data && "success" === data.message) {
        const cleanedLine = buildCleanLine(data, cleanLinePasteDataResult)
        const {mapPrimaryKey, primaryKey} = createMapPrimaryKey(program, cleanedLine)
        const cleanedJsonWithDefaultValuesNoPrimaryKey = {
            "Source": "In Service Data",
            "PN_MTBF": "N/A",
            "PN_MTBUR": "N/A",
            ...cleanedLine,
            "AC_Program": transformFieldNewValue(cleanedLine, "AC_Program", "A320", "A320 Fam")
        }
        const stringifyData = initData.map(elt => JSON.stringify(elt)).map(stringValue => stringValue.replace(/": "/gm, "\":\""))
        const stringifyInitDataNoPrimaryKeyNoStatus = stringifyDataNoPrimaryKeyFunc(initData, primaryKey, "statusCR")
        const stringifyCleanedJsonWithDefaultValuesNoPrimaryKey = JSON.stringify(
            cleanedJsonWithDefaultValuesNoPrimaryKey,
            Object.keys(cleanedJsonWithDefaultValuesNoPrimaryKey).sort())
        const indexCurrentLine = stringifyInitDataNoPrimaryKeyNoStatus.indexOf(stringifyCleanedJsonWithDefaultValuesNoPrimaryKey)

        const toastPayload = indexCurrentLine > -1 ? {
            status: "warning",
            message: messages.WARNING
        } : {
            status: "success",
            message: messages.SUCCESS
        }
        const {status} = toastPayload
        toastData.push(toastPayload)

        const newLineJson = {"statusCR": status, ...cleanedJsonWithDefaultValuesNoPrimaryKey, ...mapPrimaryKey}
        const newLine = JSON.stringify(newLineJson)
        const finalStringifiedData = indexCurrentLine < 0 ? [...stringifyData, newLine] : [...stringifyData]
        const finalData = finalStringifiedData.map((stringJson, index) => {
            return index === indexCurrentLine ? {"statusCR": status, ...JSON.parse(stringJson)} : JSON.parse(stringJson)
        })
        return finalData
    }
    return []
}

/**
 * Try to Parse a JSON and populate toastData in case of failure
 * @param json
 * @param toastData
 * @returns {*}
 */
export const tryParseJson = (transformedJson, toastData) => {
    const json = JSON.stringify(transformedJson)
    /* istanbul ignore else */
    if (isJSON(json) && Array.isArray(JSON.parse(json))) {
        const parsedJSON = JSON.parse(json).map(line => JSON.parse(JSON.stringify(line).replace(/": "/gm, "\":\"")))
        /* istanbul ignore else */
        if (!parsedJSON) {
            toastData.push({status: "error", message: messages.ERROR})
        }
        return parsedJSON
    }
}

/**
 * Build InitData
 * @param crResult
 * @param rawTableData
 * @returns {*[]}
 */
export const buildInitData = (crResult, rawTableData) => {
    let initData = crResult
    if (!initData && rawTableData && rawTableData.data) {
        initData = cloneDeep(rawTableData.data);
    }
    return initData
}


/**
 * Handle Paste Data for CR
 * @param line
 * @param crResult
 * @param toastData
 * @param columns
 * @param initData
 * @param program
 * @returns {Promise<{toastData, crResult: *[]}>}
 */
export const handlePasteDataCR = async (line, crResult, toastData, columns, initData, program) => {
    const lineKeys = Object.keys(line)
    const columnsKeys = columns.map(col => col.key)
    const ignoredColumns = ["Source", "family_id", "PN", "PN_MTBF", "AC_Program", "Component", "PN_MTBUR", "statusCR"]
    const filteredColumnsKeys = columnsKeys.filter(elt => !ignoredColumns.includes(elt))
    const isValidFields = filteredColumnsKeys.every((key) =>
        lineKeys.includes(key) && (lineKeys.includes("PN") || lineKeys.includes("family_id")))
    if (isValidFields) {
        const hasPN = lineKeys.includes("PN")
        const hasPNFamily = lineKeys.includes("family_id")
        const skipCustomColumnsFilter = ["PN_MTBF", "Component", "PN_MTBUR"]
        const customFieldsCR = hasPNOrPNFamily(hasPN, hasPNFamily, program)
        const isValidValues = isValidValueCR(columns, customFieldsCR, skipCustomColumnsFilter, line, initData)
        if (isValidValues) {
            crResult = await handleValidValues(line, customFieldsCR, initData, columns, hasPN, hasPNFamily, toastData, program)
        } else {
            toastData.push({status: "error", message: messages.ERROR})
        }
    } else {
        toastData.push({status: "error", message: messages.ERROR})
    }


    return {crResult, toastData}
}

/**
 * Function to transform value of a json
 * @param line
 * @param field
 * @param initialValue
 * @param replaceValue
 * @returns {string}
 */
export const transformFieldNewValue = (line, field, initialValue, replaceValue) => {
    let result = ''
    if (line[field]) {
        result = line[field] === initialValue ? replaceValue : line[field]
    }
    return result
}

/**
 * Join elements if data[field] is defined and is an array
 * @param data
 * @param field
 * @returns {*}
 */
export const joinArray = (data, field) => {
    let result = data[field]
    if (result && Array.isArray(result)) {
        result = data[field].join(', ')
    }
    return result
}
