import { REMOVE_MESSAGE, SET_PLASMA_BANNER, SET_PLASMA_WARNING, 
 } from "./notificationActionType"

export const removeMessage = (id) => ({ type: REMOVE_MESSAGE, payload: id })

export const setPlasmaBanner = (state) => ({
    type: SET_PLASMA_BANNER,
    payload: state,
  });

export const setPlasmaWarning = (status) => ({
    type: SET_PLASMA_WARNING,
    payload: {status}
})
