import moment from 'moment';
import {
  GET_SYNC_STATUS_SUCCESS,
  GET_SYNC_STATUS_FAILURE,
  SET_PLASMA_SYNC_INFO
} from "./syncStatusActionType";
import {
  DOSSIER_SYNC_STATUS,
  DERIVED_SYNC_STATUS,
  PLASMA_SYNC_INFO_STATUS,
  PLASMA_SYNC_MESSAGES
} from "../../config/dossier.status";

const initialState = {
  plasma_sync_info: {
    status: "",
    message: "",
    disablePlasmaButton: false
  },
};


const syncNotification = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYNC_STATUS_SUCCESS: {
      return reduceSyncStatus(state, action);
    }
    case GET_SYNC_STATUS_FAILURE: {
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.UNKNOWN,
          msg: "unable to get latest sync status",
        },
      };
    }
    case SET_PLASMA_SYNC_INFO: {
      return setPlasmaSyncInfoSatus(state,action);
    }
    default:
      return state;
  }
};

/**
 * reduces sync status data based on incoming status
 * @param {Object} state  current state of the store
 * @param {Object} action action to reduce
 */
const reduceSyncStatus = (state, action) => {
  const error = action.payload.error
    ? `[Last Sync Failed] : ${action.payload.error}`
    : "[Last Sync Failed]";
  switch (action.payload.status) {
    case DOSSIER_SYNC_STATUS.INIT:
    case DOSSIER_SYNC_STATUS.SYNC:
    case DOSSIER_SYNC_STATUS.READY: {
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.IN_PROGRESS,
          progress : action.payload.status,
          msg: "Sync in progress...",
        },
      };
    }
    case DOSSIER_SYNC_STATUS.FAIL: {
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.FAILED,
          msg: error,
        },
      };
    }
    default:
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.SUCCESS,
          progress : DERIVED_SYNC_STATUS.SUCCESS,
          msg: "",
        },
      };
  }
};

const setPlasmaSyncInfoSatus = (state, action) => {
  let message = "";

  switch (action.payload[0]) {
    case PLASMA_SYNC_INFO_STATUS.INPROGRESS:
      message = PLASMA_SYNC_MESSAGES.PLASMA_IN_PROGRESS;
      break;
    case PLASMA_SYNC_INFO_STATUS.FIRSTFETCH:
      message = PLASMA_SYNC_MESSAGES.FIRST_FETCH;
      break;
    case PLASMA_SYNC_INFO_STATUS.WARNING:
      message = PLASMA_SYNC_MESSAGES.PLASMA_TRANSFER_ISSUE;
      break;
    case PLASMA_SYNC_INFO_STATUS.ERROR:
      message = PLASMA_SYNC_MESSAGES.RETRY_STOPPED;
      break;
    case PLASMA_SYNC_INFO_STATUS.SUCCESS:
      message = `${PLASMA_SYNC_MESSAGES.FILE_TRANSFERRED_SUCCESS} ${moment(action.payload[2]).format('MMMM DD YYYY, HH:mm:ss') }`;
      break;
    default:
      message = PLASMA_SYNC_MESSAGES.FILE_YET_TO_BE_TRANSFERRED;
  }
  return {
    ...state,
    plasma_sync_info: {
      status : action.payload[0],
      message,
      disablePlasmaButton: action.payload[1],
    },
  };

};

export default syncNotification;
