
import moment from 'moment';
import { UPLOAD_FILE_FAILURE, DELETE_FILE_FAILURE, GET_IMAGEB64_FAILURE, UPDATE_USERDATA_SUCCESS } from "../userDataModel/userDataActionType";
import { GET_STATUS_FAILURE, UPDATE_STATUS_FAILURE, LIST_STATUS_FAILURE, GET_STATUS_SUCCESS, UPDATE_STATUS_SUCCESS } from '../dossierModel/dossierStatusActionType';
import { GET_USERINFO_FAILURE, GET_USERDATA_FAILURE } from '../userDataModel/userDataActionType';
import { GET_REPORTS_FAILURE, GET_TEMPLATE_FAILURE } from '../reportModel/reportActionType';
import { SAVE_EDITS_FAILURE, SAVE_EDITS_SUCCESS } from '../teEditableModel/teEditableActionType';
import { REMOVE_MESSAGE, SET_PLASMA_BANNER, SET_PLASMA_WARNING,
} from "./notificationActionType";
import { NOTIFICATIONS, STATUS, GENERIC_NOTIFICATION_MESSAGES, MSG_SHOULD_SHOWON_BANNER, BANNER_MESSAGE } from '../../config/dossier.status';

const STATUS_ID = 'STATUS_ID';
export const increment = () => {
  let id = 0;
  return () => id++;
}
const nextId = increment()
const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FILE_FAILURE:
    case UPLOAD_FILE_FAILURE:
    case GET_IMAGEB64_FAILURE:
    case UPDATE_STATUS_FAILURE:
    case GET_USERINFO_FAILURE:
    case GET_USERDATA_FAILURE:
    case GET_REPORTS_FAILURE:
    case GET_TEMPLATE_FAILURE:
    case SAVE_EDITS_FAILURE:
    case LIST_STATUS_FAILURE: {
      return reduceGenericErrorNotifications(state, action);
    }
    case SET_PLASMA_BANNER: {
      return reducePlasmaSuccessNotifications(state);
    }
    case SET_PLASMA_WARNING: {
      return reducePlasmaWarning(state, action);
    }
    case GET_STATUS_SUCCESS:
    case GET_STATUS_FAILURE:
    case UPDATE_STATUS_SUCCESS: {
      return reduceStatusNotifications(state, action);
    }
    case UPDATE_USERDATA_SUCCESS:
    case SAVE_EDITS_SUCCESS: {
      return reduceGenericSuccessNotifications(state);
    }
    case REMOVE_MESSAGE: {
      const newState = { ...state }
      delete newState[action.payload]
      return newState;
    }
    default:
      return state;
  }

};
/**
 * reduces error notification based on the action error response
 * @param {Object} state  current state of the store
 * @param {Object} action action to reduce
 */
const reduceGenericErrorNotifications = (state, action) => {
  const { response } = action.error;
  const message = response && response.data ? response.data.errorMessage || response.data.error_description : "unknown error";
  return {
    ...state,
    [nextId()]: {
      message,
      status: 'error',
      closable: true,
      ...(action.autoHideDuration) && { autoHideDuration: action.autoHideDuration }
    }
  };
}

/**
 * reduces success  notification based on the action success response
 * @param {Object} state  current state of the store
 */
const reduceGenericSuccessNotifications = (state) => {
  return {
    ...state,
    [nextId()]: {
      message: GENERIC_NOTIFICATION_MESSAGES['EDIT_SUCCESS'],
      status: 'success',
      closable: true,
      autoHideDuration: 10000,
      description: ''
    }
  };
}
/**
 * reduces success  notification based on the action success response
 * @param {Object} state  current state of the store
 */
const reducePlasmaSuccessNotifications = (state) => {
  return {
    ...state,
    [nextId()]: {
      message: GENERIC_NOTIFICATION_MESSAGES['FILE_SUCCESS'],
      status: 'success',
      closable: true,
      autoHideDuration: 10000,
      description: '' ,
      plasmaBanner : true
    }
  };
}
const reducePlasmaWarning =(state, action) => {
  return {
    ...state,
    [STATUS_ID]: {
      ...state[STATUS_ID],
      status: action.payload.status,
    }
  }
}

/**
 * reduces status notification based on the dossier status
 * @param {Object} state  current state of the store
 * @param {Object} action action to reduce
 */
const reduceStatusNotifications = (state, action) => {
  const role = action.user && action.user.role ? action.user.role : 'default';
  const status = action.payload ? action.payload.status.replace(/\s/g, '_') : 'error';
  const description = action.payload
    ? `Data synchronized at ${moment(action.payload.createdAt).format('MMMM DD YYYY, HH:mm:ss')}`
    : '';
  const [
    isRollbackFromMPEReview , isRejectedComments, isDefault, keyMsg
  ] = computeNotificationType(action.payload,status);

 const notification = isRollbackFromMPEReview || isRejectedComments || isDefault;

  return {
    ...state,
    [STATUS_ID]: {
      message: notification.msg[role].replace('{msg1}', keyMsg || ''),
      status: notification.type,
      description: description,
      closable: false
    }
  }
}


/**
 * Compute the notification msg and type
 * @param {*} dossier
 * @param {*} notificationType
 * @returns
 */
const computeNotificationType = (dossier ,notificationType) => {

  const isDossierMPEReviewWithComments = MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITH_COMMENT ===  (dossier && dossier.mpeCommentsStatus)
  const isDossierMPEReviewWithoutComments = MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITHOUT_COMMENT ===  (dossier && dossier.mpeCommentsStatus)
  const isrejectedDossier = MSG_SHOULD_SHOWON_BANNER.CHAIR_PERSON_REJECT ===  (dossier && dossier.mpeCommentsStatus)
  const isRollbackMPEReviewTrue = dossier && dossier.rollbackMPEReview;

  const notificationResult = new Array(4).fill(undefined);
  const NOTIFICATION_INDEX_MAP = {
    isRollbackFromMPEReview : 0,
    isRejectedComments: 1,
    isDefault: 2,
    keyMsg: 3
  }

  notificationResult[NOTIFICATION_INDEX_MAP.isDefault] = NOTIFICATIONS[notificationType] || NOTIFICATIONS.unknown;
  const NOTIFICATION_RULE_MAP = {
    [STATUS.TECHNICAL_EVALUATION]: () => {
      notificationResult[NOTIFICATION_INDEX_MAP.isRollbackFromMPEReview] = isRollbackMPEReviewTrue && NOTIFICATIONS.rollback_mpe_review;
      notificationResult[NOTIFICATION_INDEX_MAP.isRejectedComments] = isrejectedDossier && NOTIFICATIONS.rejected;
      notificationResult[NOTIFICATION_INDEX_MAP.keyMsg] =  (isDossierMPEReviewWithComments && BANNER_MESSAGE.TE_MPE_WITH_COMMENT)
                                                          || (isDossierMPEReviewWithoutComments && BANNER_MESSAGE.TE_MPE_WITHOUT_COMMENT)
    },
    [STATUS.REVIEW]: () => {
      notificationResult[NOTIFICATION_INDEX_MAP.keyMsg] = (isDossierMPEReviewWithComments && BANNER_MESSAGE.RV_MPE_WITH_COMMENT)
                                                          || (isDossierMPEReviewWithoutComments && BANNER_MESSAGE.RV_MPE_WITHOUT_COMMENT)
    },
    [STATUS.ACCEPTED]: () => {
      notificationResult[NOTIFICATION_INDEX_MAP.keyMsg] = null
    },
    [STATUS.DELIVERED]: () => {
      notificationResult[NOTIFICATION_INDEX_MAP.keyMsg] = (isDossierMPEReviewWithComments && BANNER_MESSAGE.RV_MPE_WITH_COMMENT)
                                                          || (isDossierMPEReviewWithoutComments && BANNER_MESSAGE.RV_MPE_WITHOUT_COMMENT)
    }
  }

  dossier && NOTIFICATION_RULE_MAP[dossier.status] && NOTIFICATION_RULE_MAP[dossier.status]();

  return notificationResult;
}

export default notificationReducer;
