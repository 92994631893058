import React, {  useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Autorenew, ErrorOutline } from "@airbus/icons/react";
import PropTypes from "prop-types";
import { getSyncStatus, setPlasmaSyncStatus } from "../../models/syncStatusModel/syncStatusApi";
import { DERIVED_SYNC_STATUS, PLASMA_SYNC_STATUS, PLASMA_SYNC_MESSAGES, PLASMA_SYNC_INFO_STATUS , STATUS} from "../../config/dossier.status.js";
import { ProgressBar } from "@airbus/components-react";
import { getStatus } from "../../models/dossierModel/dossierStatusApi.js";
import { setPlasmaWarning } from "../../models/notificationModel/notificationActions.js";
const MAX_RETRY = 5;

const ProgressBarStatusMap = {
  "INIT": 0,
  "SYNC" : 30,
  "READY" : 65,
  "ZIPPED" : 100,
}

const PlasmaProgressBarStatusMap = {
  INIT: 0,
  IN_PROGRESS: 25,
  SUCCESS_HTML_CREATION: 50,
  FAIL_HTML_CREATION: 0,
  SUCCESS_HTML_READY: 75,
  FAIL_HTML_READY: 0,
  SUCCESS_HTML_TRANSFER: 100,
  FAIL_HTML_TRANSFER: 0,
};

const renderSyncProgress = (syncStatusData) => {
  const isSyncing = syncStatusData.status === DERIVED_SYNC_STATUS.IN_PROGRESS;
  return (
    <div>
    <span className="syncStatus">
      {isSyncing ? (
        <Autorenew className={"rotate"} />
      ) : (
        <ErrorOutline />
      )}
      <span>{syncStatusData.msg}</span>
    </span>
    {isSyncing && <div ><ProgressBar className='progress-bar' striped status="success" showPercentage value={ProgressBarStatusMap[syncStatusData.progress]} /> </div>}
    </div>
  );
};

const renderPlasmaSyncProgress = (plasmaSyncStatusData, statusMessage, retryCount, dossierKey) => {
  const isPlasmaSyncing =
      plasmaSyncStatusData &&
      plasmaSyncStatusData.dossierKey ===  dossierKey &&
      ![ PLASMA_SYNC_STATUS.INIT, PLASMA_SYNC_STATUS.SUCCESS_HTML_TRANSFER].includes(plasmaSyncStatusData?.htmlFileStatus) &&
      retryCount < MAX_RETRY &&
      ![PLASMA_SYNC_INFO_STATUS.ERROR].includes(statusMessage?.status)

return (
  <div>
    <span>
      {plasmaSyncStatusData?.htmlFileStatus === PLASMA_SYNC_STATUS.INIT &&
      [STATUS.DELIVERED, STATUS.CLOSED].includes(plasmaSyncStatusData?.status) && (
        <span>{PLASMA_SYNC_MESSAGES.FILE_YET_TO_BE_TRANSFERRED}</span>
      )}
      {isPlasmaSyncing && [STATUS.DELIVERED, STATUS.CLOSED].includes(plasmaSyncStatusData?.status) && (
        <><Autorenew className={"rotate"} /> <span>{statusMessage.message}</span> </>
      )}
    </span>
    {[PLASMA_SYNC_INFO_STATUS.SUCCESS, PLASMA_SYNC_INFO_STATUS.ERROR].includes(statusMessage?.status) &&
      [STATUS.DELIVERED, STATUS.CLOSED].includes(plasmaSyncStatusData?.status) && (
        <span>{statusMessage.message}</span>
      )}
    {isPlasmaSyncing && [STATUS.DELIVERED, STATUS.CLOSED].includes(plasmaSyncStatusData?.status) &&(
      <div> <ProgressBar className="progress-bar" striped showPercentage
            value={PlasmaProgressBarStatusMap[plasmaSyncStatusData?.htmlFileStatus]} /> </div>
    )}
  </div>
);
};

const SyncStatus = (props) => {
  const [wasSyncing, setWasSyncing] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const SYNC_POLL_INTERVAL = 30000;
  const PLASMA_SYNC_POLL_INTERVAL = 2000;

  const syncStatusData = useSelector(
    (store) => store.syncNotification.sync_info
  );
  const plasmaSyncInfo = useSelector(
    (store) => store.syncNotification.plasma_sync_info
  );
  const plasmaSyncStatusData = useSelector(
    (store) => store.dossierStatus
  );

  const dossierKey = useSelector((store) => store.reports.selectedReport);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSyncStatus(dossierKey));
    dispatch(getStatus(dossierKey))
  }, [dispatch, dossierKey]);

  const firstState = useRef(true);
  useEffect(() => {
    const { dossierKey, htmlFileStatus, htmlFileTransferred } = plasmaSyncStatusData || {};
    const plasmaSyncInProgress =
      dossierKey === dossierKey &&
      ![PLASMA_SYNC_STATUS.INIT, PLASMA_SYNC_STATUS.SUCCESS_HTML_TRANSFER].includes(htmlFileStatus) &&
      retryCount <= MAX_RETRY &&
     [STATUS.DELIVERED, STATUS.CLOSED].includes(plasmaSyncStatusData?.status)

    if (htmlFileStatus === PLASMA_SYNC_STATUS.SUCCESS_HTML_TRANSFER) {  // If plasma sync is successful, update the status and exit
        dispatch(setPlasmaSyncStatus([PLASMA_SYNC_INFO_STATUS.SUCCESS, false, htmlFileTransferred]));
        return;
    }
    if (!plasmaSyncInProgress) {return;}  // If plasma sync is not in progress, exit early
    dispatch(setPlasmaSyncStatus([PLASMA_SYNC_INFO_STATUS.INPROGRESS, true])); // sync in progress
    let plasmaSyncStatusTimeout;
    const hasSyncFailed = htmlFileStatus?.includes(PLASMA_SYNC_INFO_STATUS.FAIL);
    const warning = firstState.current ? PLASMA_SYNC_INFO_STATUS.FIRSTFETCH : PLASMA_SYNC_INFO_STATUS.WARNING;
    if (hasSyncFailed)  {
        const shouldRetry = retryCount < MAX_RETRY;
        dispatch(setPlasmaWarning(shouldRetry ? PLASMA_SYNC_INFO_STATUS.WARNING : PLASMA_SYNC_INFO_STATUS.ERROR));
        dispatch(setPlasmaSyncStatus([shouldRetry ?  warning : PLASMA_SYNC_INFO_STATUS.ERROR, shouldRetry]));
        if (!shouldRetry) {return;}
        plasmaSyncStatusTimeout = setTimeout(() => {
          dispatch(getStatus(dossierKey));
          setRetryCount((prev) => prev + 1);
        }, PLASMA_SYNC_POLL_INTERVAL);
        return () => clearTimeout(plasmaSyncStatusTimeout);
    }
    firstState.current = false;
    // success dispatch
    plasmaSyncStatusTimeout = setTimeout(() => {
      dispatch(getStatus(dossierKey));
      }, PLASMA_SYNC_POLL_INTERVAL);
  }, [plasmaSyncStatusData, dossierKey, dispatch, retryCount]);

  useEffect(() => {
    const syncInProgress =
      syncStatusData &&
      syncStatusData.status === DERIVED_SYNC_STATUS.IN_PROGRESS;
    let syncStatusTimeout;
    if (syncInProgress) {
      setWasSyncing(true);
      syncStatusTimeout = setTimeout(() => {
        dispatch(getSyncStatus(dossierKey));
      }, SYNC_POLL_INTERVAL);
    }
    return () => {
      clearTimeout(syncStatusTimeout);
    };
  }, [syncStatusData, dossierKey, dispatch]);

  const syncIncomplete = syncStatusData && syncStatusData.status !== DERIVED_SYNC_STATUS.SUCCESS;
  const plasmaIncomplete = plasmaSyncStatusData && plasmaSyncStatusData.htmlFileStatus !== "SUCCESS_FILE_TRANSFER";

  wasSyncing && !syncIncomplete && window.location.reload();

  return (
    <span>
      {!syncIncomplete && props.desc}
      {syncIncomplete && renderSyncProgress(syncStatusData)}
      {plasmaIncomplete && renderPlasmaSyncProgress(plasmaSyncStatusData, plasmaSyncInfo, retryCount, dossierKey)}
    </span>
  );
};

export default SyncStatus;

SyncStatus.propTypes = {
  desc: PropTypes.string.isRequired,
};
