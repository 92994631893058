import React from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@airbus/components-react";
import FileIcon, { defaultStyles } from 'react-file-icon';
import { useSelector } from "react-redux";
import idx from 'idx';

import NoData from "../NoData/NoData";
import DOMPurify from 'dompurify';  // import sanitization library

import "./FileAttachment.scss"

function sanitizeUrl(url) {
  const sanitizedUrl = DOMPurify.sanitize(url, { ALLOWED_URI_REGEXP: /^(https?):/ });
  return sanitizedUrl;
}

const FileAttachment = (props) => {
  const { content, sectionName } = idx(props, _ => _)
  const { reportName } = useParams();
  const { attachments } = useSelector(store => store.userData);
  // filter attachments with sectionName == sectionName in which it is rendered
  const filteredAttachments = attachments.filter(data => data.ref === sectionName);
  const renderLink = (attachment) => {
    const link = `${window.location.origin}/AttachmentsHandler/${reportName}/${attachment.name}`;
    return (
      <a
        target="_blank"
        className="pointer"
        rel="noopener noreferrer"
        href={sanitizeUrl(link)}  // sanitize the link before passing it to href
      >
        <FileIcon 
          extension={getFileExt(attachment.name)} 
          size={40} 
          {...defaultStyles[getFileExt(attachment.name)]} 
        />
      </a>
    )
  }
  return (
    <Container className="file-attachments-cls">
      <Table className="attachments-table">
        <TableBody>
          {filteredAttachments.length ? filteredAttachments.map((attachment, index) => (
            <TableRow key={index}>
             {content.label === 'Attachments' ? <TableCell> Appendix {index + 1} </TableCell> : null }
              <TableCell>{attachment.name}</TableCell>
              <TableCell>{attachment.description}</TableCell>
              <TableCell className="icon-cls">
              {navigator.webdriver ? (
                  <div>
                    <FileIcon extension={getFileExt(attachment.name)} size={40} {...defaultStyles[getFileExt(attachment.name)]} />
                  </div>)
                  : renderLink(attachment)
                }
              </TableCell>
            </TableRow>
          )): ( <NoData columns={[]} /> )}
        </TableBody>
      </Table>

    </Container>
  );
};
export const getFileExt = file => file.split('.').pop();

export default FileAttachment;
