import { API_METHOD_GET, GET_SYNC_STATUS } from "../../utils/api.actions";
import getConfig from "../../config";
import { API_CALL } from "../../utils/api.middleware";
import {
  GET_SYNC_STATUS_SUCCESS,
  GET_SYNC_STATUS_FAILURE,
  SET_PLASMA_SYNC_INFO
} from "./syncStatusActionType";
const config = getConfig();
const apiName = config.API.endpoints[0].name;

const getSyncStatusSuccess = (data, passData, store) => ({  // eslint-disable-line no-unused-vars
  type: GET_SYNC_STATUS_SUCCESS,
  payload: data.body,
});
const getSyncStatusFailure = (error, passData, store) => ({   // eslint-disable-line no-unused-vars
  type: GET_SYNC_STATUS_FAILURE,
  error: error,
});

export const getSyncStatus = (dossierKey) => {
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: GET_SYNC_STATUS.replace("{key}", dossierKey),
      method: API_METHOD_GET,
    },
    handlers: [getSyncStatusSuccess, getSyncStatusFailure],
    componentName: "getSyncStatus",
  };
};

export const setPlasmaSyncStatus = (data) => ({
  type: SET_PLASMA_SYNC_INFO,
  payload: data
})
